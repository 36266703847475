import { Theme } from "@mui/material/styles";

export default function Common(theme: Theme): Theme["components"] {
  return {
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: theme.palette.grey7.light,
          opacity: 1,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        fontSizeSmall: {
          fontSize: "1.1rem",
        },
      },
    },
  };
}
