import { SvgIcon, SvgIconProps } from "@mui/material";

const DiscoveryIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon role="img" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M7.33333 16.3956C4.54472 14.8768 3 11.9708 3 8.78571C3 3.93482 7.04675 0 12 0C16.9533 0 21 3.93482 21 8.78571C21 11.9708 19.4553 14.8768 16.6667 16.3956V19.5C16.6667 19.9741 16.2597 20.3571 15.7562 20.3571H8.2438C7.74031 20.3571 7.33333 19.9741 7.33333 19.5V16.3956ZM8.58536 22.0714H15.4146C15.5399 22.0714 15.6436 22.1762 15.6436 22.2971V23.1429C15.6436 23.617 15.2366 24 14.7331 24H9.2669C8.76341 24 8.35643 23.617 8.35643 23.1429V22.2971C8.35643 22.1762 8.46009 22.0714 8.58536 22.0714Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default DiscoveryIcon;
