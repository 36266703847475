import APIInstance from "@/utils/apiConfig";
import { AxiosResponse } from "axios";
import { WorkBookResponse } from "@/models/workbook";

const sharedWithMeApi = {
  getList(): Promise<AxiosResponse<WorkBookResponse>> {
    return APIInstance.get(`/workbooks/sharedwithme`);
  },
};

export default sharedWithMeApi;
