export type TUserRole = "admin" | "viewer" | "creator" | "approver";

export enum EUserRole {
  ADMIN = "admin",
  VIEWER = "viewer",
  CREATOR = "creator",
  APPROVER = "approver",
}
export interface User {
  id: string;
  email: string;
  fullname: string;
  school: string;
  groups: string;
  role: TUserRole[];
  branchid: string;
  branchname: string;
  divisionid: string;
  divisionname: string;
}

export const adminApproverCreatorRoles = [EUserRole.ADMIN, EUserRole.APPROVER, EUserRole.CREATOR];
