import { CircularProgress, Stack, SxProps } from "@mui/material";

const LoadingScreen = ({ isLoading, sx }: { isLoading?: boolean; sx?: SxProps }) => (
  <Stack
    direction="column"
    justifyContent="center"
    alignItems="center"
    height="100vh"
    bgcolor={!isLoading ? "white" : "rgba(255, 255, 255, 0.5)"}
    top="0"
    zIndex={99999999}
    width="100%"
    position={"fixed"}
    sx={sx}
  >
    <CircularProgress />
  </Stack>
);

export default LoadingScreen;
