import { Typography } from "@mui/material";
import { SectionBoxProps } from "@/modules/help/components/section-box";

export const USER_GUIDE_CONFIG: SectionBoxProps[] = [
  {
    boxTitle: "Getting Started",
    links: [
      {
        title: "What is DVP Portal",
        href: "/",
      },
      {
        title: "Quick Start",
        href: "/",
      },
      {
        title: "Navigate through Website",
        href: "/",
      },
      {
        title: "What are the different Menu in DVP",
        href: "/",
      },
    ],
  },
  {
    boxTitle: "View Dashboards",
    links: [
      {
        title: "Discover Dashboards",
        href: "/",
      },
      {
        title: "Add Dashboards to Favourites",
        href: "/",
      },
      {
        title: "Request access to view dashboard",
        href: "/",
      },
      {
        title: "Send remainder to request",
        href: "/",
      },
      {
        title: "View Dashboards",
        href: "/",
      },
      {
        title: "Comment on Dashboards",
        href: "/",
      },
    ],
  },
  {
    boxTitle: "Edit Dashboards",
    links: [
      {
        title: "Request access to Edit",
        href: "/",
      },
      {
        title: "Edit dashboards in DVP",
        href: "/",
      },
      {
        title: "Edit dashboards in workspace",
        href: "/",
      },
      {
        title: "Edit dashboards using Tableau Desktop",
        href: "/",
      },
      {
        title: "Save edited dashboards",
        href: "/",
      },
    ],
  },
  {
    boxTitle: "Create Dashboards (for creators)",
    links: [
      {
        title: "Create dashboards in workspace",
        href: "/",
      },
      {
        title: "Upload dashboard in DVP",
        href: "/",
      },
      {
        title: "Edit dashboards in DVP",
        href: "/",
      },
      {
        title: "Edit dashboards in workspace",
        href: "/",
      },
      {
        title: "Edit dashboards using Tableau Desktop",
        href: "/",
      },
    ],
  },
  {
    boxTitle: "Share Dashboards (for creators)",
    links: [
      {
        title: "Share dashboards with other users",
        href: "/",
      },
      {
        title: "Approve/reject dashboard access requests",
        href: "/",
      },
      {
        title: "Set view/edit permission for dashboard",
        href: "/",
      },
      {
        title: "See who can view shared dashboard",
        href: "/",
      },
      {
        title: "Revoke access to dashboard",
        href: "/",
      },
    ],
  },
  {
    boxTitle: "Resources",
    links: [
      {
        title: "Template for dashboard design",
        href: "/",
      },
      {
        title: "Dashboard best practices",
        href: "/",
      },
      {
        title: "Row level security for dashboard",
        href: "/",
      },
    ],
  },
];

export const VIDEOS_CONFIG: SectionBoxProps[] = [
  {
    boxTitle: "Requests",
    links: [
      {
        title: "Quick start guide (4:22 min)",
        href: "/",
      },
      {
        title: "Navigation thru website (3:08 min)",
        href: "/",
      },
      {
        title: "...",
        href: "/",
      },
    ],
  },
  {
    boxTitle: "Dashboards",
    links: [
      {
        title: "Guide (11:03 min)",
        href: "/",
      },
      {
        title: "How to (2:03 min)",
        href: "/",
      },
      {
        title: "Connect with your data (1:09 min)",
        href: "/",
      },
    ],
  },
];

export const FAQ_CONFIG = [
  {
    boxTitle: "Question 1",
    boxContent: (
      <Typography>
        Answer <em>1</em>
      </Typography>
    ),
  },
  {
    boxTitle: "Question 2",
    boxContent: (
      <Typography>
        Answer <em>2</em>
      </Typography>
    ),
  },
];
