import { SvgIcon, SvgIconProps } from "@mui/material";

const ShareIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon role="img" viewBox="0 0 48 48" fill="none" {...props}>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M35 16C37.7614 16 40 13.7614 40 11C40 8.23858 37.7614 6 35 6C32.2386 6 30 8.23858 30 11C30 13.7614 32.2386 16 35 16Z"
        fill="transparent"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinejoin="miter"
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M13 29C15.7614 29 18 26.7614 18 24C18 21.2386 15.7614 19 13 19C10.2386 19 8 21.2386 8 24C8 26.7614 10.2386 29 13 29Z"
        fill="transparent"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinejoin="miter"
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M30.0004 13.5745L17.3393 21.2454"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="miter"
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M17.3385 26.5639L30.6789 34.4469"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="miter"
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M35 32C37.7614 32 40 34.2386 40 37C40 39.7614 37.7614 42 35 42C32.2386 42 30 39.7614 30 37C30 34.2386 32.2386 32 35 32Z"
        fill="transparent"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinejoin="miter"
      />
    </SvgIcon>
  );
};

export default ShareIcon;
