import { useEffect } from "react";
import { queryKeys } from "@/constants";
import { useQuery } from "@tanstack/react-query";
import { isEmpty } from "lodash";
import { Project, Workbook, WorkbookTransform } from "@/models/workbook";
import { NotifyService } from "@/helpers/notify";
import sharedWithMeApi from "@/services/share-with-me.api";

interface UseListSharedWithMeProps {
  enabledToastError?: boolean;
}

export const useListSharedWithMe = (
  { enabledToastError = false }: UseListSharedWithMeProps = { enabledToastError: false }
) => {
  const query = useQuery({
    queryKey: [queryKeys.sharedWithMe],
    queryFn: async () => {
      const response = await sharedWithMeApi.getList();
      const workbooks: Workbook[] = isEmpty(response.data.workbooks)
        ? []
        : Object.values(response.data.workbooks);
      const projects: Project[] = isEmpty(response.data.projects)
        ? []
        : Object.values(response.data.projects);

      return { workbooks, projects } as WorkbookTransform;
    },
  });

  useEffect(() => {
    if (query.error) {
      // eslint-disable-next-line no-console
      console.error("useListSharedWithMe", query.error.message);
      enabledToastError && NotifyService.error(query.error.message);
    }
  }, [query.error, enabledToastError]);

  return query;
};
