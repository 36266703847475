import { Theme } from "@mui/material/styles";

// eslint-disable-next-line no-unused-vars
export default function Table(_: Theme): Theme["components"] {
  return {
    MuiTable: {},
    MuiTableBody: {},
    MuiTableCell: {},
    MuiTableRow: {},
  };
}
