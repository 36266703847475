import { lazy } from "react";

export const AuthenticationFeature = lazy(() => import("@/modules/authentication"));
export const Discovery = lazy(() => import("@/modules/discovery"));
export const DraftContainer = lazy(() => import("@/modules/draft/DraftContainer"));
export const EditScreen = lazy(() => import("@/modules/edit"));
export const Favourites = lazy(() => import("@/modules/favourites"));
export const MyWorkbook = lazy(() => import("@/modules/my-workbooks"));
export const WorkbookDetails = lazy(() => import("@/modules/my-workbooks/details"));
export const Recent = lazy(() => import("@/modules/recent"));
export const Request = lazy(() => import("@/modules/request"));
export const SettingLayout = lazy(() => import("@/modules/settings"));
export const HelpLayout = lazy(() => import("@/modules/help"));
export const Share = lazy(() => import("@/modules/shared-with-me"));
export const Statistics = lazy(() => import("@/modules/statistics"));
export const ViewScreen = lazy(() => import("@/modules/view"));
export const Workspace = lazy(() => import("@/modules/workspace"));
