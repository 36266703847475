import { createContext, ReactNode, useCallback, useEffect } from "react";
import { USER_LOGGED_IN_KEY } from "@/constants";
import { clearLS } from "@/utils/auth";
import { config } from "@/utils/config";
import { useQueryClient } from "@tanstack/react-query";
import { useAtom } from "jotai";
import useMyProfile from "@/hooks/authentication/useMyProfile";
import { User } from "./authentication.type";
import { userLoggedInAtom } from "./store";

export interface AuthContextProps {
  isPending: boolean;
  profile: User | null;
  isLoggedIn: boolean;
  login: (user: User | undefined) => void;
  logout: () => void;
}

export const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const queryClient = useQueryClient();
  const [profile, setProfile] = useAtom<string | null>(userLoggedInAtom);
  const { data: sessionUser, isPending } = useMyProfile();

  const login = (user: User | undefined) => {
    if (!user) {
      logout();
      return;
    }
    setProfile(JSON.stringify(user));
  };

  const logout = useCallback(() => {
    queryClient.clear();
    setProfile(null);
    clearLS();
  }, [queryClient, setProfile]);

  useEffect(() => {
    function checkLocalStorage() {
      const localStorageValue = localStorage.getItem(USER_LOGGED_IN_KEY);
      if (!localStorageValue) {
        logout();
      }
    }
    window.addEventListener("focus", checkLocalStorage);
    return () => {
      window.removeEventListener("focus", checkLocalStorage);
    };
  }, [logout]);

  const parsedUser: User =
    profile !== null && typeof profile === "string" ? JSON.parse(profile) : profile || null;

  return (
    <AuthContext.Provider
      value={{
        isPending: config().ENABLED_SAML ? isPending : false,
        profile: parsedUser,
        isLoggedIn: config().ENABLED_SAML
          ? Boolean(sessionUser?.data?.user?.MIMSLoginID) && Boolean(parsedUser)
          : Boolean(parsedUser),
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
