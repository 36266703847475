import APIInstance from "@/utils/apiConfig";
import { AxiosResponse } from "axios";
import {
  ApproverRequest,
  ApproverResponse,
  EditPermissionPayload,
  MyWorkbooksDetail,
  OwnerRequest,
  OwnerResponse,
  Permission,
  PermissionRequest,
  PublishWorkbookPayload,
  UpdateWorkbookPayload,
} from "@/models/my-workbooks";
import { BranchAndDivisionResponse, WorkBookResponse } from "@/models/workbook";

export const myWorkbooksApi = {
  getList: async (): Promise<AxiosResponse<WorkBookResponse>> => {
    return APIInstance.get("/workbooks/myworkbooks");
  },
  getDetail: async (workbookid: string): Promise<AxiosResponse<MyWorkbooksDetail>> => {
    return APIInstance.get(`/permissions/${workbookid}`);
  },

  getBranchAndDivision: async (): Promise<AxiosResponse<BranchAndDivisionResponse>> => {
    return APIInstance.get(`/divisionsandbranches`);
  },

  editPermission: (
    workbookid: string,
    payload: EditPermissionPayload
  ): Promise<AxiosResponse<unknown>> => {
    return APIInstance.post(`/permissions/${workbookid}`, payload);
  },
  uploadWorkbook: async (data: FormData) => {
    return APIInstance.post(`/uploadwb`, data, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  },
  publishWorkbook: (payload: PublishWorkbookPayload): Promise<AxiosResponse<unknown>> => {
    return APIInstance.post(`/publishwb`, payload);
  },

  updateWorkbook: (payload: UpdateWorkbookPayload): Promise<AxiosResponse<unknown>> => {
    return APIInstance.post(`/updatewb`, payload);
  },
  getPermission: async (workbookid: string): Promise<AxiosResponse<Permission>> => {
    return APIInstance.get(`/permissions/${workbookid}`);
  },
  changePermission: async (workbookid: string, request: PermissionRequest) => {
    return APIInstance.post(`/permissions/${workbookid}`, request);
  },
  deletePermission: async (workbookid: string, request: PermissionRequest) => {
    return APIInstance.delete(`/permissions/${workbookid}`, { data: request });
  },
  getOwner: async (workbookid: string): Promise<AxiosResponse<OwnerResponse>> => {
    return APIInstance.get(`/owner/${workbookid}`);
  },
  getAllOwner: async (): Promise<AxiosResponse<OwnerResponse>> => {
    return APIInstance.get("/owner/listall");
  },
  addOwner: async (workbookid: string, request: OwnerRequest) => {
    return APIInstance.post(`/owner/${workbookid}`, request);
  },
  removeOwner: async (workbookid: string, request: OwnerRequest) => {
    return APIInstance.delete(`/owner/${workbookid}`, { data: request });
  },
  getApprover: async (workbookid: string): Promise<AxiosResponse<ApproverResponse>> => {
    return APIInstance.get(`/approver/${workbookid}`);
  },
  getAllApprover: async (): Promise<AxiosResponse<ApproverResponse>> => {
    return APIInstance.get("/approver/listall");
  },
  addApprover: async (workbookid: string, request: ApproverRequest) => {
    return APIInstance.post(`/approver/${workbookid}`, request);
  },
  removeApprover: async (workbookid: string, request: ApproverRequest) => {
    return APIInstance.delete(`/approver/${workbookid}`, { data: request });
  },
};
