import { SvgIcon, SvgIconProps } from "@mui/material";

const SignOutIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon role="img" viewBox="0 0 24 24" fill="currentColor" {...props}>
      <path
        d="M21.5356 17.8929H19.6526C19.524 17.8929 19.4035 17.9491 19.3231 18.0482C19.1356 18.2759 18.9347 18.4956 18.7231 18.7045C17.8576 19.5708 16.8325 20.2612 15.7044 20.7375C14.5356 21.2312 13.2794 21.4844 12.0106 21.4822C10.7276 21.4822 9.48472 21.2304 8.31686 20.7375C7.18872 20.2612 6.16358 19.5708 5.29811 18.7045C4.4311 17.8411 3.73977 16.8177 3.2624 15.6911C2.76686 14.5232 2.51776 13.2831 2.51776 12C2.51776 10.717 2.76954 9.4768 3.2624 8.30894C3.73918 7.18127 4.4249 6.16609 5.29811 5.29555C6.17133 4.42501 7.18651 3.7393 8.31686 3.26251C9.48472 2.76966 10.7276 2.51787 12.0106 2.51787C13.2936 2.51787 14.5365 2.76698 15.7044 3.26251C16.8347 3.7393 17.8499 4.42501 18.7231 5.29555C18.9347 5.50716 19.1329 5.7268 19.3231 5.9518C19.4035 6.05091 19.5267 6.10716 19.6526 6.10716H21.5356C21.7044 6.10716 21.8088 5.91966 21.7151 5.77769C19.6606 2.58484 16.066 0.471443 11.9811 0.482157C5.56329 0.498229 0.417755 5.70805 0.482041 12.1179C0.546327 18.4259 5.68383 23.5179 12.0106 23.5179C16.0847 23.5179 19.6633 21.4072 21.7151 18.2223C21.8061 18.0804 21.7044 17.8929 21.5356 17.8929ZM23.9169 11.8313L20.116 8.83127C19.974 8.71877 19.7678 8.82055 19.7678 9.00002V11.0357H11.357C11.2392 11.0357 11.1428 11.1322 11.1428 11.25V12.75C11.1428 12.8679 11.2392 12.9643 11.357 12.9643H19.7678V15C19.7678 15.1795 19.9767 15.2813 20.116 15.1688L23.9169 12.1688C23.9425 12.1487 23.9632 12.1231 23.9774 12.0939C23.9917 12.0646 23.9991 12.0325 23.9991 12C23.9991 11.9675 23.9917 11.9354 23.9774 11.9062C23.9632 11.8769 23.9425 11.8513 23.9169 11.8313Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default SignOutIcon;
