import { SvgIcon, SvgIconProps } from "@mui/material";

const RecentIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      role="img"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.0196 8.16696C18.7928 4.92857 15.6669 2.625 12.0054 2.625C8.34376 2.625 5.21787 4.92312 3.99101 8.16152C1.68481 8.77233 -0.0133956 10.8804 -2.71741e-06 13.3821C0.0160686 16.1785 2.18571 18.4527 4.91785 18.6964C5.04375 18.7072 5.15176 18.608 5.15176 18.4821V16.8643C5.15176 16.7573 5.07053 16.6661 4.96363 16.6526C4.24019 16.5617 3.55715 16.2457 3.03214 15.7285C2.38928 15.0981 2.03571 14.2527 2.03571 13.3553C2.03571 12.6066 2.27946 11.8982 2.7375 11.3089C3.18482 10.7357 2.79321 10.3205 3.09375 10.1416L5.52321 9.5375L5.89536 8.8875C6.125 8.27679 6.44732 7.70357 6.85179 7.18641C7.24643 6.67232 7.72437 6.22231 8.25536 5.84732C9.35625 5.07321 10.652 4.66339 12.0054 4.66339C13.3587 4.66339 14.6545 5.07321 15.7554 5.85089C16.2882 6.22589 16.7643 6.67589 17.1589 7.19107C17.5536 7.70625 17.8759 8.27946 18.1054 8.89241L18.4844 9.5375L19.4974 9.89241C20.9496 10.2578 21.9643 11.8446 21.9643 13.3527C21.9643 14.3536 21.6107 15.1634 20.9679 15.725C20.4464 16.2402 19.7654 16.6491 19.042 16.65C18.9351 16.6645 18.8562 16.7573 18.8562 16.8643V18.4821C18.8562 18.608 18.9661 18.7072 19.0911 18.6964C21.8161 18.4518 23.9812 16.1785 24 13.3875C24.0161 10.8857 22.3205 8.77768 20.0196 8.16696Z"
        fill="currentColor"
      />
      <path
        d="M8.38128 15.8678C8.42947 14.9705 8.80179 14.1349 9.43928 13.4946C10.1864 12.8116 11.0464 12.4286 12.0107 12.4286C12.9804 12.4286 13.8937 12.8062 14.5821 13.4946C14.623 13.5313 14.6612 13.5679 14.698 13.6099L14.0223 14.1349C13.9892 14.1595 13.9617 14.1939 13.9403 14.2349C13.9189 14.276 13.9045 14.3214 13.9159 14.3641C13.9272 14.4068 13.9531 14.4458 13.9916 14.4737C14.0301 14.5016 14.0785 14.5163 14.128 14.5245L16.7331 15.1553C16.8661 15.1848 16.9983 15.0857 16.9983 14.9491L17.0112 11.9027C17.0112 11.7897 16.808 11.6982 16.6661 11.8L16.0446 12.5545C15.0643 11.4295 13.6223 10.7161 12.0125 10.7161C9.1367 10.7161 6.79017 13.0295 6.67266 16.0054C6.66699 16.1388 6.79621 16.2536 6.92991 16.2536H8.16967C8.29259 16.2509 8.40169 16.1634 8.38128 15.8678ZM17.1167 16.2518H15.8769C15.7539 16.2518 15.6449 16.3384 15.6308 16.4621C15.5857 17.358 15.2134 18.1938 14.5759 18.8353C13.8214 19.5188 12.9617 19.9045 12.0054 19.9045C11.0451 19.9045 10.1317 19.5268 9.4433 18.8353C9.40335 18.796 9.36339 18.7567 9.32142 18.7125L9.99714 18.1349C10.0367 18.1063 10.0653 18.0656 10.0844 18.0223C10.1035 17.9789 10.1103 17.9321 10.1003 17.8875C10.0903 17.8429 10.0642 17.8022 10.0268 17.774C9.98936 17.7459 9.94241 17.7321 9.89465 17.7237L7.28952 17.0929C7.15652 17.0634 7.02435 17.1625 7.02435 17.2991L7.0385 20.3509C7.0385 20.4746 7.24174 20.5661 7.38363 20.4643L8.00514 19.71C9.29997 20.7161 10.742 21.4286 12.0107 21.4286C14.8864 21.4286 17.2329 19.1152 17.3504 16.1393C17.356 16.0062 17.2272 16.2518 17.1167 16.2518Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
export default RecentIcon;
