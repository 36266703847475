import { Box } from "@mui/material";
import NavigationListItem from "@/components/common/navigation-list-item";

const settings = [
  { path: "/settings/categories", name: "Categories" },
  { path: "/settings/groups", name: "Groups" },
  { path: "/settings/approvers", name: "Approvers" },
];
const SettingSection = () => {
  return (
    <Box
      sx={{
        pr: 1,
        bgcolor: "common.white",
        color: "text.primary",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {settings.map((st) => (
        <NavigationListItem key={st.name} label={st.name} isOpen path={st.path} isSubItem />
      ))}
    </Box>
  );
};

export default SettingSection;
