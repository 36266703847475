import { User } from "../../authentication.type";
import { CheckPermissionConfig, Roles } from "./permission.type";

const permissionCheckTypeMethods = {
  "one-of": (roles: Roles) => roles.some,
  "all-of": (roles: Roles) => roles.every,
};
export const checkPermission = (user: User, roles: Roles, config: CheckPermissionConfig = {}) => {
  const { type = "one-of" } = config;

  const checkMethod = permissionCheckTypeMethods?.[type] || permissionCheckTypeMethods["one-of"];

  const userRoles = user?.role || [];

  const hasAccess = checkMethod(roles).bind(roles)((role) => {
    return userRoles.includes(role);
  });

  return hasAccess;
};
