import axios from "axios";
import { getAccessTokenFromLS } from "./auth";
import { config } from "./config";

const APIInstance = axios.create({
  baseURL: config().BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Add a request interceptor
APIInstance.interceptors.request.use(
  async function (config) {
    const token = getAccessTokenFromLS();
    config.headers["x-api-token"] = token || "";

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
APIInstance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export default APIInstance;
