import React from "react";
import { APP_ROUTES } from "@/routers/routes";
import { EUserRole } from "@/modules/authentication/authentication.type";
import {
  PermissionType,
  Roles,
} from "@/modules/authentication/components/Permission/permission.type";
import {
  DiscoveryIcon,
  FavouriteIcon,
  QuestionIcon,
  RecentIcon,
  RequestIcon,
  ShareIcon,
  WorkspaceIcon,
} from "@/assets/icons";
import SettingIcon from "@/assets/icons/settings-icon";

export interface Item {
  label: string;
  icon?: React.ReactNode;
  path: string;
  permission?: {
    roles: Roles;
    type?: PermissionType;
  };
}
export const APP_MENU: Item[] = [
  {
    label: "Discovery",
    icon: <DiscoveryIcon />,
    path: APP_ROUTES.DISCOVERY.to,
  },
  {
    label: "Favourites",
    icon: <FavouriteIcon />,
    path: APP_ROUTES.FAVOURITES.to,
  },
  {
    label: "Recent",
    icon: <RecentIcon />,
    path: APP_ROUTES.RECENT.to,
  },
  {
    label: "My Dashboards",
    icon: <WorkspaceIcon />,
    path: APP_ROUTES.MY_WORKBOOK.to,
    permission: {
      roles: [EUserRole.ADMIN, EUserRole.CREATOR],
    },
  },
  {
    label: "Shared with me",
    icon: <ShareIcon />,
    path: APP_ROUTES.SHARE_WITH_ME.to,
  },
  {
    label: "Request",
    icon: <RequestIcon />,
    path: APP_ROUTES.REQUEST.to,
    permission: {
      roles: [EUserRole.ADMIN, EUserRole.CREATOR, EUserRole.APPROVER, EUserRole.VIEWER],
    },
  },
  {
    label: "Workspace",
    icon: <WorkspaceIcon />,
    path: APP_ROUTES.WORKSPACE.to,
    permission: {
      roles: [EUserRole.ADMIN, EUserRole.CREATOR, EUserRole.APPROVER],
    },
  },
  {
    label: "Statistics",
    icon: <WorkspaceIcon />,
    path: APP_ROUTES.STATISTICS.to,
    permission: {
      roles: [EUserRole.ADMIN, EUserRole.CREATOR, EUserRole.APPROVER],
    },
  },
  {
    label: "Settings",
    icon: <SettingIcon />,
    path: APP_ROUTES.SETTINGS.to,
    permission: {
      roles: [EUserRole.ADMIN],
    },
  },
  {
    label: "Help",
    icon: <QuestionIcon />,
    path: APP_ROUTES.HELP.to,
  },
];
