import { ReactElement } from "react";
import { Navigate } from "react-router-dom";
import { APP_ROUTES } from "@/routers/routes";
import useAuth from "@/hooks/authentication/useAuth";
import LoadingScreen from "@/components/common/loading-screen";

interface RequireAuthProps {
  children: ReactElement;
  redirectTo?: string;
}

function RequireAuth({ children, redirectTo = APP_ROUTES.LOGIN.to }: RequireAuthProps) {
  const { isLoggedIn, isPending } = useAuth();

  if (isPending) return <LoadingScreen />;

  if (!isLoggedIn) return <Navigate replace to={redirectTo} />;

  return children;
}

export default RequireAuth;
