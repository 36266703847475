import { Grid, Menu, Stack, styled, Typography } from "@mui/material";
import { StyledMenuProps, w } from "@/components/layout/dashboard-layout/styles";
import { theme } from "@/theme";

export const StyledContainerMenuLeft = styled(Grid)<StyledMenuProps>`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
export const StyledToggleMenu = styled(Stack)<StyledMenuProps>`
  width: ${({ open }) => (open ? w.menu[1] : w.menu[2])};
  transition: width linear 0.1s;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
`;
export const StyledMenuTop = styled(Grid)<StyledMenuProps>`
  background-color: ${theme.palette.white.light};
  height: 80px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid;
  border-color: ${({ theme }) => theme.palette.neutralLight[400]};
  justify-content: ${({ open }) => (open ? "flex-start" : "center")};
  color: ${({ theme }) => theme.palette.common.black};
  padding: ${({ open }) => (open ? "0 16px" : 0)};
  & span {
    padding-left: ${({ open }) => (open ? "18px" : 0)};
  }
`;

export const StyledMenuBottom = styled(Stack)`
  background-color: #006afb;
  padding: 7px 0 7px 0;
  align-items: flex-end;
  & .MuiIconButton-root {
    transform: rotate(180deg);
  }
  & .MuiIconButton-root.open {
    transform: rotate(0deg);
  }
`;

export const StyledMenuCenter = styled(Stack)`
  flex: 1;
  background: #006afb;
  color: ${theme.palette.neutralLight[600]};
  padding-top: 16px;
`;

export const StyledMenu = styled(Menu)`
  & .MuiButtonBase-root {
    padding: 16px;
    width: 164px;
    height: 33px;
  }
  & .MuiList-root {
    padding: 0px;
  }
`;
export const StyledTextMenu = styled(Typography)<StyledMenuProps>`
  visibility: ${({ open }) => (open ? "visible" : "hidden")};
  width: ${({ open }) => (open ? "auto" : 0)};
  transition: opacity 0.2s ease-out;
  transition: width 0.2s ease-out;
  white-space: nowrap;
  transition-delay: ${({ open }) => (open ? "0.1s" : 0)};
`;
