import {
  MOE_STATISTIC_URL,
  MOE_WORKSPACE_URL,
  USERLIST_MOCKUP,
  VNV_STATISTIC_URL,
  VNV_WORKSPACE_URL,
} from "@/constants";
import { FAQ_CONFIG, USER_GUIDE_CONFIG, VIDEOS_CONFIG } from "@/constants/help";

export function config() {
  const isVNVEnv = import.meta.env.VITE_IS_VNV_BASE_URL === "true";
  const prefixURL = isVNVEnv ? "https://moe-api-dev.vinova.sg" : "https://uat.eduhubdvp.moe.gov.sg";
  const baseURL = isVNVEnv ? prefixURL : `${prefixURL}/api`;

  const workbookFileSize = splitFileSize(import.meta.env.VITE_WORKBOOK_FILE_SIZE);
  const tableauLoginUrl = import.meta.env.VITE_LOGIN_TABLEAU;
  const enabledSAML = import.meta.env.VITE_USE_SAML === "true";
  const samlLoginUrl = "/login/sso";
  const samlLogoutUrl = "/logout";
  // Delete permission message
  const deleteMessageByUser = (username: string) =>
    `Are you sure you want to remove the permission for user ${username}`;

  return {
    BASE_URL: baseURL,
    VIEW_TABLEAU: `${prefixURL}/view`,
    EDIT_TABLEAU: `${prefixURL}/edit`,
    WORKSPACE_URL: isVNVEnv ? VNV_WORKSPACE_URL : MOE_WORKSPACE_URL,
    STATISTIC_URL: isVNVEnv ? VNV_STATISTIC_URL : MOE_STATISTIC_URL,
    IS_DEV_ENV: isVNVEnv,
    USERLIST: USERLIST_MOCKUP,
    WORKBOOK_FILE_SIZE: workbookFileSize.value,
    WORKBOOK_FILE_UNIT: workbookFileSize.unit,
    TABLEAU_LOGIN_URL: tableauLoginUrl,
    ENABLED_SAML: enabledSAML,
    SAML_LOGIN_URL: samlLoginUrl,
    SAML_LOGOUT_URL: samlLogoutUrl,
    HELP_USER_GUIDE_CONFIG: USER_GUIDE_CONFIG,
    HELP_VIDEO_CONFIG: VIDEOS_CONFIG,
    HELP_FAQ_CONFIG: FAQ_CONFIG,
    deleteMessageByUser: deleteMessageByUser,
  };
}

function splitFileSize(fileSizeStr: string) {
  const regex = /^(\d+)([a-zA-Z]+)$/;
  const match = fileSizeStr.match(regex);

  if (!match) {
    return {
      value: 40,
      unit: "MB",
    };
  }

  const value = parseInt(match[1], 10);
  const unit = match[2].toUpperCase();

  return {
    value: value,
    unit: unit,
  };
}
