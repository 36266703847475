import { useEffect } from "react";
import { openMenu } from "@/store/global";
import { config } from "@/utils/config";
import { useAtom } from "jotai";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { Box, IconButton } from "@mui/material";
import { APP_ROUTES } from "@/routers/routes";
import useAuth from "@/hooks/authentication/useAuth";
import NavigationListItem from "@/components/common/navigation-list-item";
import { checkPermission } from "@/modules/authentication/components/Permission/checkPermission";
import { LogoIcon, SignOutIcon, ToggleMenuIcon } from "@/assets/icons";
import Category from "./components/Category";
import { APP_MENU } from "./constant";
import {
  StyledContainerMenuLeft,
  StyledMenuBottom,
  StyledMenuCenter,
  StyledMenuTop,
  StyledTextMenu,
  StyledToggleMenu,
} from "./styles";

const MenuLeft = () => {
  const location = useLocation();
  const isUseSAML = config().ENABLED_SAML;
  const { profile } = useAuth();
  const pathname = location.pathname;
  const [isOpen, setIsOpen] = useAtom(openMenu);
  const { logout } = useAuth();
  const navigate = useNavigate();
  const handleOpenMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleSignOut = () => {
    if (isUseSAML) window.location.href = config().SAML_LOGOUT_URL;
    else {
      logout();
      navigate(APP_ROUTES.LOGIN.to);
    }
  };

  useEffect(() => {
    const STATISTIC_URL = config().STATISTIC_URL;
    const WORKSPACE_URL = config().WORKSPACE_URL;
    if (pathname === APP_ROUTES.WORKSPACE.to) {
      window.open(WORKSPACE_URL);
    } else if (pathname === APP_ROUTES.STATISTICS.to) {
      window.open(STATISTIC_URL);
    }
  }, [pathname]);

  return (
    <StyledContainerMenuLeft open={true}>
      <StyledMenuTop open={isOpen}>
        <LogoIcon />
        <StyledTextMenu variant="Bold_18" open={isOpen}>
          Data <br />
          Visualisation
          <br />
          Portal (Beta)
        </StyledTextMenu>
      </StyledMenuTop>
      <StyledToggleMenu open={isOpen}>
        <StyledMenuCenter>
          {APP_MENU.map((menu) => {
            let isHasAccess = true;
            if (profile?.role && menu.permission)
              isHasAccess = checkPermission(profile, menu.permission?.roles, {
                type: menu.permission?.type,
              });

            // Check exact for pages except setting
            const matchExact =
              menu.path !== APP_ROUTES.SETTINGS.to &&
              menu.path !== APP_ROUTES.REQUEST.to &&
              matchPath({ path: menu.path, end: true }, pathname);

            // Setting page have child routes so check match relative
            const matchRelative =
              (menu.path === APP_ROUTES.SETTINGS.to && pathname.startsWith(menu.path.toString())) ||
              (menu.path === APP_ROUTES.REQUEST.to && pathname.startsWith(menu.path.toString()));

            if (isHasAccess) {
              return (
                <Box key={menu.label}>
                  <NavigationListItem
                    label={menu.label}
                    icon={menu.icon}
                    isOpen={isOpen}
                    path={menu.path}
                  />
                  {(matchExact || matchRelative) && (
                    <Box
                      className={`nav-category ${isOpen ? "open" : ""}`}
                      sx={{
                        "&.open": {
                          display: "block",
                        },
                        display: "none",
                      }}
                    >
                      <Category path={menu.path} />
                    </Box>
                  )}
                </Box>
              );
            }
            return null;
          })}
        </StyledMenuCenter>
        <StyledMenuBottom
          direction="column"
          justifyContent="space-between"
          className={isOpen ? "" : "small"}
        >
          <NavigationListItem
            label="Sign out"
            icon={<SignOutIcon />}
            isOpen={isOpen}
            onClick={handleSignOut}
          />
          <IconButton
            onClick={handleOpenMenu}
            className={isOpen ? "open" : ""}
            sx={{ color: "common.white" }}
          >
            <ToggleMenuIcon />
          </IconButton>
        </StyledMenuBottom>
      </StyledToggleMenu>
    </StyledContainerMenuLeft>
  );
};
export default MenuLeft;
