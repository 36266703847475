export const queryKeys = {
  discovery: "discovery",
  favourite: "favourite",
  recent: "recent",
  workbook: "workbook",
  request: "request",
  myRequest: "myRequest",
  authorization: "authorization",
  comment: "comment",
  sharedWithMe: "sharedWithMe",
  workbook_permission: "workbook_permission",
  settingCategory: "settingCategory",
  settingGroup: "settingGroup",
  settingApprover: "settingApprover",
  workbook_owner: "workbookOwner",
  workbook_owners: "workbookOwners",
  workbook_approvers: "workbookApprovers",
  workbook_approver: "workbookApprover",
  workbookBranchDivision: "workbookBranchDivision",
} as const;
