import { SvgIcon, SvgIconProps } from "@mui/material";

const ToggleMenuIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      role="img"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.14 7.755H21.34C21.461 7.755 21.56 7.656 21.56 7.535V5.995C21.56 5.874 21.461 5.775 21.34 5.775H8.14C8.019 5.775 7.92 5.874 7.92 5.995V7.535C7.92 7.656 8.019 7.755 8.14 7.755ZM7.92 13.365C7.92 13.486 8.019 13.585 8.14 13.585H21.34C21.461 13.585 21.56 13.486 21.56 13.365V11.825C21.56 11.704 21.461 11.605 21.34 11.605H8.14C8.019 11.605 7.92 11.704 7.92 11.825V13.365ZM21.78 0H0.22C0.099 0 0 0.099 0 0.22V1.76C0 1.881 0.099 1.98 0.22 1.98H21.78C21.901 1.98 22 1.881 22 1.76V0.22C22 0.099 21.901 0 21.78 0ZM21.78 17.38H0.22C0.099 17.38 0 17.479 0 17.6V19.14C0 19.261 0.099 19.36 0.22 19.36H21.78C21.901 19.36 22 19.261 22 19.14V17.6C22 17.479 21.901 17.38 21.78 17.38ZM0.0935 9.86975L4.39175 13.255C4.55125 13.3815 4.78775 13.2687 4.78775 13.0652V6.29475C4.78775 6.09125 4.554 5.9785 4.39175 6.105L0.0935 9.49025C0.0645981 9.51272 0.041211 9.5415 0.0251235 9.57438C0.00903597 9.60726 0.000673065 9.64339 0.000673065 9.68C0.000673065 9.71661 0.00903597 9.75273 0.0251235 9.78562C0.041211 9.8185 0.0645981 9.84728 0.0935 9.86975Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
export default ToggleMenuIcon;
