import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { CommonCategory } from "@/models/workbook";
import { useListSharedWithMe } from "@/hooks/shared-with-me/useListSharedWithMe";
import CategoryFilter from "@/components/common/category-filter";

const SharedWithMeCategory = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [defaultCategory, setDefaultCategory] = useState<CommonCategory[]>([]);
  const [initialChecked, setInitialChecked] = useState<string[]>([]);
  const { data, isPending } = useListSharedWithMe();

  useEffect(() => {
    if (!data || isPending) return;
    setDefaultCategory(data.projects);
    const categories = data.projects.map((item) => item.id) || [];
    const categoriesParams = searchParams.get("categories");
    if (categories.length > 0 || categoriesParams)
      setSearchParams({ categories: categoriesParams || categories.join(",") });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isPending]);

  useEffect(() => {
    if (!searchParams.get("categories")) return;
    const categories = searchParams.get("categories")?.split(",") || [];
    setInitialChecked(categories);
  }, [searchParams]);

  const handleCategoryFilter = (checkedCategory: string[]) => {
    if (checkedCategory.length === 0) {
      setSearchParams((prev) => {
        prev.delete("categories");
        return prev;
      });
      return;
    }
    setSearchParams({ categories: checkedCategory.join(",") });
  };

  return (
    <CategoryFilter
      isLoading={isPending}
      categoryOptions={defaultCategory}
      onChange={handleCategoryFilter}
      initialChecked={initialChecked}
    />
  );
};

export default SharedWithMeCategory;
