import { Outlet } from "react-router-dom";

import MenuLeft from "./menu-left";
import { MainContainer, StyledMainMenuLeft, StyledMainMenuRight } from "./styles";

function DashboardLayout() {
  return (
    <MainContainer>
      <StyledMainMenuLeft>
        <MenuLeft />
      </StyledMainMenuLeft>
      <StyledMainMenuRight>
        <Outlet />
      </StyledMainMenuRight>
    </MainContainer>
  );
}

export default DashboardLayout;
