import { cloneElement, isValidElement } from "react";
import { matchPath, NavLink, To, useLocation } from "react-router-dom";
import { ButtonBase, ListItemIcon, ListItemText, SvgIconProps, SxProps } from "@mui/material";
import { StyledListItemButton } from "./styles";

interface NavigationListItemProps {
  icon?: React.ReactNode;
  label: string;
  isOpen: boolean;
  path?: To;
  onClick?: () => void;
  sx?: SxProps;
  isSubItem?: boolean;
}
const NavigationListItem = ({
  path,
  icon,
  isOpen,
  label,
  onClick,
  sx,
  isSubItem = false,
}: NavigationListItemProps) => {
  const location = useLocation();
  const isPreventClick = path
    ? matchPath({ path: location.pathname, end: true }, path?.toString())
    : false;
  const isActive = path && location.pathname.startsWith(path.toString());

  const clonedIcon = icon
    ? isValidElement(icon)
      ? cloneElement(icon, { fontSize: "small" } as SvgIconProps)
      : icon
    : undefined;

  return (
    <StyledListItemButton
      component={path ? NavLink : ButtonBase}
      to={path}
      onClick={onClick}
      className={isActive ? "active" : ""}
      $isPreventClick={Boolean(isPreventClick)}
      $isSubItem={isSubItem}
      sx={sx}
    >
      {clonedIcon && <ListItemIcon>{clonedIcon}</ListItemIcon>}
      {label && isOpen && <ListItemText>{label}</ListItemText>}
    </StyledListItemButton>
  );
};

export default NavigationListItem;
