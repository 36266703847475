import { USER_LOGGED_IN_KEY } from "@/constants";
import { atomWithStorage } from "jotai/utils";

// Define atom with storage for isLoggedIn
export const userLoggedInAtom = atomWithStorage(
  USER_LOGGED_IN_KEY,
  localStorage.getItem(USER_LOGGED_IN_KEY),
  {
    getItem(key, initialValue) {
      const storedValue = localStorage.getItem(key);
      try {
        return JSON.parse(storedValue ?? "");
      } catch {
        return initialValue;
      }
    },
    setItem(key, value) {
      localStorage.setItem(key, value as string);
    },
    removeItem(key) {
      localStorage.removeItem(key);
    },
  }
);
