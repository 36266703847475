import { Box } from "@mui/material";
import useAuth from "@/hooks/authentication/useAuth";
import NavigationListItem from "@/components/common/navigation-list-item";
import { EUserRole } from "@/modules/authentication/authentication.type";
import { checkPermission } from "@/modules/authentication/components/Permission/checkPermission";
import { Roles } from "@/modules/authentication/components/Permission/permission.type";

type RequestUrl = {
  path: string;
  name: string;
  roles?: Roles;
};
const requestUrls: RequestUrl[] = [
  { path: "/request/my-requests", name: "Requests" },
  { path: "/request/approvals", name: "Approvals", roles: [EUserRole.ADMIN, EUserRole.CREATOR] },
  {
    path: "/request/authorisation",
    name: "Authorisations",
    roles: [EUserRole.ADMIN, EUserRole.APPROVER],
  },
];
const RequestSection = () => {
  let urls: RequestUrl[] = [];
  const { profile } = useAuth();

  if (!profile) {
    urls = [];
  } else {
    urls = requestUrls.filter((u) => {
      if (!u.roles) return true;
      return checkPermission(profile, u.roles);
    });
  }

  return (
    <Box
      sx={{
        pr: 1,
        bgcolor: "common.white",
        color: "text.primary",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {urls.map((u) => (
        <NavigationListItem key={u.name} label={u.name} isOpen path={u.path} isSubItem />
      ))}
    </Box>
  );
};

export default RequestSection;
