import { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import { CommonCategory } from "@/models/workbook";
import InputFieldUI from "@/components/form-control/input-field/ui";

interface CategoryFilterProps {
  isLoading?: boolean;
  categoryOptions: CommonCategory[];
  initialChecked?: string[];
  onChange: (checkedValues: string[]) => void;
}

const CategoryFilter = ({
  isLoading = false,
  categoryOptions,
  initialChecked = [],
  onChange,
}: CategoryFilterProps) => {
  const [searchCategory, setSearchCategory] = useState<string>("");
  const [checkedOptions, setCheckedOptions] = useState<string[]>(initialChecked);

  useEffect(() => {
    setCheckedOptions(initialChecked);
  }, [initialChecked]);

  const handleSearch = (value: string) => {
    setSearchCategory(value);
  };

  const handleCheckboxChange = (value: string, checked: boolean) => {
    const updatedCheckedOptions = checked
      ? [...checkedOptions, value]
      : checkedOptions.filter((option) => option !== value);

    setCheckedOptions(updatedCheckedOptions);
    onChange(updatedCheckedOptions.filter((option) => option !== "_all"));
  };

  const filteredCategories = categoryOptions?.filter((category) =>
    category.name.toLowerCase().includes(searchCategory.toLowerCase())
  );

  const handleChangeAllCheckbox = (checked: boolean) => {
    if (checked) {
      const updatedCheckedOptions = categoryOptions.map((item) => item.id);
      setCheckedOptions(updatedCheckedOptions);
      onChange(updatedCheckedOptions);
    } else {
      setCheckedOptions([]);
      onChange([]);
    }
  };

  const isCheckedAll = categoryOptions?.every((item) => checkedOptions.includes(item.id));
  const isIndeterminateCheckedAll =
    categoryOptions?.some((item) => checkedOptions.includes(item.id)) && !isCheckedAll;

  return (
    <Box
      sx={{
        pl: 4,
        pr: 1,
        pt: 1,
        pb: 2,
        bgcolor: "common.white",
        color: "text.primary",
      }}
    >
      <Typography variant="subtitle1" fontWeight={700}>
        Categories
      </Typography>
      <InputFieldUI
        placeholder="Search list category below"
        value={searchCategory}
        onChange={handleSearch}
        fullWidth
        sx={{ bgcolor: "common.white" }}
      />
      <FormGroup sx={{ marginTop: "8px", flexWrap: "nowrap", maxHeight: 42 * 6, overflow: "auto" }}>
        {isLoading && (
          <Grid
            display="flex"
            justifyContent="center"
            sx={{ marginTop: "20px", marginBottom: "20px" }}
          >
            <CircularProgress />
          </Grid>
        )}
        {!isLoading && filteredCategories.length > 0 && (
          <FormControlLabel
            control={
              <Checkbox
                value={"_all"}
                color="primary"
                checked={isCheckedAll}
                onChange={(e) => handleChangeAllCheckbox(e.target.checked)}
                indeterminate={isIndeterminateCheckedAll}
              />
            }
            label="All"
            componentsProps={{ typography: { variant: "Reg_14" } }}
          />
        )}
        {!isLoading &&
          filteredCategories.length > 0 &&
          filteredCategories.map((item) => (
            <FormControlLabel
              key={item.id}
              control={
                <Checkbox
                  value={item.id}
                  color="primary"
                  checked={checkedOptions.includes(item.id)}
                  onChange={(e) => handleCheckboxChange(item.id, e.target.checked)}
                />
              }
              label={item.name}
              componentsProps={{ typography: { variant: "Reg_14" } }}
            />
          ))}
        {!isLoading && filteredCategories.length === 0 && (
          <Typography variant="subtitle2" color="text.disabled">
            No category found
          </Typography>
        )}
      </FormGroup>
    </Box>
  );
};

export default CategoryFilter;
