import APIInstance from "@/utils/apiConfig";
import axios, { AxiosResponse } from "axios";
import { TokenRequest, TokenResponse } from "@/hooks/authentication/useLogin";

const authApi = {
  login({ email }: TokenRequest): Promise<AxiosResponse<TokenResponse>> {
    return APIInstance.post("/getusertoken", { email });
  },
  getProfile() {
    return axios.get("/me");
  },
};

export default authApi;
