import { ReactElement } from "react";
import { Navigate } from "react-router-dom";
import { APP_ROUTES } from "@/routers/routes";
import useAuth from "@/hooks/authentication/useAuth";
import LoadingScreen from "@/components/common/loading-screen";

type Props = {
  children: ReactElement;
};

const RequireAnonymous = ({ children }: Props) => {
  const { isLoggedIn, profile, isPending } = useAuth();

  if (isPending) return <LoadingScreen />;

  if (!isLoggedIn) {
    return children;
  }

  return profile ? <Navigate replace to={APP_ROUTES.DISCOVERY.to} /> : children;
};

export default RequireAnonymous;
