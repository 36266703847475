import { tabClasses } from "@mui/material";
import { Theme } from "@mui/material/styles";

// eslint-disable-next-line no-unused-vars
export default function Tabs(_: Theme): Theme["components"] {
  return {
    MuiTabs: {},
    MuiTab: {
      styleOverrides: {
        root: {
          [`&.${tabClasses.selected}`]: {
            fontWeight: 700,
          },
        },
      },
    },
  };
}
