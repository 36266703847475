import { transientOptions } from "@/utils/transientOptions";
import { NavLinkProps, To } from "react-router-dom";
import {
  ButtonBase,
  ButtonBaseTypeMap,
  ExtendButtonBase,
  ListItemButton,
  listItemIconClasses,
  styled,
  typographyClasses,
} from "@mui/material";

interface Props {
  $isPreventClick?: boolean;
  $isSubItem?: boolean;
  component?:
    | React.ForwardRefExoticComponent<NavLinkProps & React.RefAttributes<HTMLAnchorElement>>
    | ExtendButtonBase<ButtonBaseTypeMap<object, "button">>
    | typeof ButtonBase;
  to?: To; // To allow passing the `to` prop for NavLink
  onClick?: () => void; // To allow passing the `onClick` handler
}
export const StyledListItemButton = styled(
  ListItemButton,
  transientOptions
)<Props>(({ $isPreventClick, $isSubItem, theme }) => ({
  width: "100%",
  paddingLeft: $isSubItem ? theme.spacing(8) : theme.spacing(4),
  height: 56,
  color: theme.palette.common.white,
  fontWeight: 400,
  overflow: "hidden",
  position: "relative",
  [`.${listItemIconClasses.root}`]: {
    color: "currentcolor",
    minWidth: 32,
  },
  "&::before": {
    content: '""',
    position: "absolute",
    display: "block",
    backgroundColor: "#f9c621",
    transition: "all 0.3s ease-in-out",
    transitionDelay: "0.2s",
    ...($isSubItem && {
      top: "50%",
      // left: 0,
      width: 0,
      height: 4,
      transform: "translate(-16px, -50%)",
    }),
    ...(!$isSubItem && {
      top: 0,
      left: -7,
      width: 7,
      height: "100%",
    }),
  },

  "&.active": {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    "&::before": {
      ...($isSubItem && {
        width: theme.spacing(1),
        height: theme.spacing(1),
      }),
      ...(!$isSubItem && {
        left: 0,
      }),
    },
    [`.${typographyClasses.root}`]: {
      fontWeight: 700,
    },
  },
  [`.${typographyClasses.root}`]: {
    ...($isSubItem && {
      fontSize: theme.typography.body2.fontSize,
      color: theme.palette.primary.main,
    }),
    ...(!$isSubItem && {}),
  },
  ...($isPreventClick && {
    pointerEvents: "none",
  }),
}));
