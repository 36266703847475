import useAuth from "@/hooks/authentication/useAuth";
import { User } from "../../authentication.type";
import { checkPermission } from "./checkPermission";
import { Debug, PermissionType, Roles } from "./permission.type";

export interface PermissionProps {
  children: React.ReactNode;
  roles: Roles;
  type?: PermissionType;
  noAccess?:
    | React.ReactNode
    | ((args: { user: User | null; hasAccess: boolean }) => React.ReactNode);
  debug?: Debug;
}

function Permission(props: PermissionProps) {
  const { roles = [], type = "one-of", debug = false, children, noAccess } = props;

  const { profile } = useAuth();

  const hasAccess = profile
    ? checkPermission(profile, roles, {
        type,
        debug,
      })
    : false;

  const renderNoAccess = () => {
    if (typeof noAccess === "function") {
      return noAccess({
        user: profile,
        hasAccess,
      });
    }
    return noAccess;
  };

  return hasAccess ? children : renderNoAccess() || null;
}

export default Permission;
