import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TOAST_CONFIG } from "./constants";
import { Routes } from "./routers";
import { ThemeProvider } from "./theme";

function App() {
  // const environment = `${import.meta.env.MODE}`;
  return (
    <ThemeProvider>
      <Routes />
      {/* {environment} */}
      <ToastContainer {...TOAST_CONFIG} />
    </ThemeProvider>
  );
}

export default App;
