import APIInstance from "@/utils/apiConfig";
import { AxiosResponse } from "axios";
import { FormSetFavourite } from "@/models/favourite";
import { WorkBookResponse } from "@/models/workbook";

export const favouriteApi = {
  getList: async (): Promise<AxiosResponse<WorkBookResponse>> => {
    return APIInstance.get("/workbooks/favourite");
  },
  setFavourite: async (data: FormSetFavourite) => {
    return APIInstance.post("/setfavourite", data);
  },
};
