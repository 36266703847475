import { Theme } from "@mui/material/styles";

export default function Forms(theme: Theme): Theme["components"] {
  return {
    MuiFormControl: {},
    MuiFormControlLabel: {},
    MuiFormGroup: {},
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          ...theme.typography.caption,
          // color: theme.palette.neutral[2],
          "&.Mui-error": {
            color: theme.palette.error.main,
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          marginBottom: "4px",
          ...theme.typography.subtitle2,
          fontWeight: "700",
          // ...theme.typography.body7,
          // color: theme.palette.neutral[1],
        },
        focused: {
          color: theme.palette.primary.main,
        },
        asterisk: {
          color: theme.palette.error.main,
        },
      },
    },
  };
}
