import APIInstance from "@/utils/apiConfig";
import { AxiosResponse } from "axios";
import { WorkBookResponse } from "@/models/workbook";

const discoveryApi = {
  getAll(): Promise<AxiosResponse<WorkBookResponse>> {
    return APIInstance.get("/workbooks");
  },
};

export default discoveryApi;
