import { useEffect } from "react";
import { queryKeys } from "@/constants";
import { useQuery } from "@tanstack/react-query";
import { isEmpty } from "lodash";
import { Project, Workbook, WorkbookTransform } from "@/models/workbook";
import { NotifyService } from "@/helpers/notify";
import { myWorkbooksApi } from "@/services/my-workbooks.api";

interface UseGetListMyWorkbooksProps {
  enabledToastError?: boolean;
  refetchOnWindowFocus?: boolean;
}

export const useGetListMyWorkbooks = (
  { enabledToastError = false, refetchOnWindowFocus = false }: UseGetListMyWorkbooksProps = {
    enabledToastError: false,
    refetchOnWindowFocus: undefined,
  }
) => {
  const query = useQuery({
    queryKey: [queryKeys.workbook],
    queryFn: async () => {
      const response = await myWorkbooksApi.getList();
      const workbooks: Workbook[] = isEmpty(response.data.workbooks)
        ? []
        : Object.values(response.data.workbooks);
      const projects: Project[] = isEmpty(response.data.projects)
        ? []
        : Object.values(response.data.projects);

      return { workbooks, projects } as WorkbookTransform;
    },
    refetchOnWindowFocus,
  });

  useEffect(() => {
    if (query.error) {
      // eslint-disable-next-line no-console
      console.error("useGetListMyWorkbooks", query.error.message);
      enabledToastError && NotifyService.error(query.error.message);
    }
  }, [query.error, enabledToastError]);

  return query;
};
