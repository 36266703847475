import { config } from "@/utils/config";
import { useQuery } from "@tanstack/react-query";
import authApi from "@/services/auth.api";

function useMyProfile() {
  const mutation = useQuery({
    queryKey: ["profile"],
    queryFn: async () => {
      return await authApi.getProfile();
    },
    refetchOnWindowFocus: false,
    enabled: config().ENABLED_SAML,
  });

  return mutation;
}

export default useMyProfile;
