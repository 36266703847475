import { APP_ROUTES } from "@/routers/routes";
import DiscoveryCategory from "./DiscoveryCategory";
import FavouriteCategory from "./FavouriteCategory";
import MyWorkbooksCategory from "./MyWorkbooksCategory";
import RecentCategory from "./RecentCategory";
import RequestSection from "./RequestSection";
import SettingSection from "./SettingSection";
import SharedWithMeCategory from "./SharedWithMeCategory";

interface CategoryProps {
  path: string;
}
const Category = ({ path }: CategoryProps) => {
  const renderCategory = () => {
    switch (path) {
      case APP_ROUTES.DISCOVERY.to:
        return <DiscoveryCategory />;
      case APP_ROUTES.RECENT.to:
        return <RecentCategory />;
      case APP_ROUTES.FAVOURITES.to:
        return <FavouriteCategory />;
      case APP_ROUTES.MY_WORKBOOK.to:
        return <MyWorkbooksCategory />;
      case APP_ROUTES.SHARE_WITH_ME.to:
        return <SharedWithMeCategory />;
      case APP_ROUTES.SETTINGS.to:
        return <SettingSection />;
      case APP_ROUTES.REQUEST.to:
        return <RequestSection />;
    }
  };
  return renderCategory();
};

export default Category;
