import { ToastOptions } from "react-toastify";
import { User } from "@/modules/authentication/authentication.type";

export const functionKeys = [
  8, // Backspace
  9, // Tab
  13, // Enter
  16, // Shift
  17, // Ctrl
  18, // Alt
  20, // Caps Lock
  27, // Escape
  35, // End
  36, // Home
  37, // Left arrow
  38, // Up arrow
  39, // Right arrow
  40, // Down arrow
  45, // Insert
  46, // Delete
  91, // Left Window key
  92, // Right Window key
  93, // Select key
];

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PASSWORD_REGEX = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*()_+{}|:"<>?]{6,20}$/;

/**
 *
 */
export const ERROR_MESSAGE = {
  /**This field is required. */
  IEM1: "This field is required.",
};

export const SUCCESS_MESSAGE = {
  SM1: "Your account is created successfully!",
};

export const CONFIRM_MESSAGE = {
  CM1: "Are you sure you want to delete this item?",
};
export const TOAST_CONFIG: ToastOptions = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export const INIT_COUNTRY_CODE = { code: "+65", name: "sg" };

export const ERROR_CODE = {
  1001: 1001, // Invalid email or password
  1004: 1004, // Token is invalid
  4001: 4001, // Token has expired
  4002: 4002, // Token incorrect
};

export const PAGE_LIMIT = 20;

export const TABLEAU_VIEW_URL =
  "https://prod-apsoutheast-a.online.tableau.com/t/oakconsultingtableauonline/views/MerchantAnalytics-PaymentProvider-USEReady/Summary";

export const TABLEAU_EDIT_URL =
  "https://prod-apsoutheast-a.online.tableau.com/t/oakconsultingtableauonline/views/MerchantAnalytics-PaymentProvider-USEReady/Summary";

export const VNV_WORKSPACE_URL = "https://prod-apsoutheast-a.online.tableau.com/";

export const MOE_WORKSPACE_URL = "https://uat.workspace.eduhubdvp.moe.gov.sg/";

export const VNV_STATISTIC_URL =
  "https://prod-apsoutheast-a.online.tableau.com/#/site/oakconsultingtableauonline/status";

export const MOE_STATISTIC_URL = "https://uat.workspace.eduhubdvp.moe.gov.sg/#/server/status";

export const ALLOW_WORKBOOK_FILE_TYPE = ["twb", "twbx"];

export const USERLIST_MOCKUP: User[] = [
  {
    id: "1",
    branchid: "1",
    divisionid: "1",
    groups: "",
    school: "Oak Consulting",
    fullname: "Creator user",
    email: "keerthi@oakconsulting.com.sg",
    role: ["creator"],
    branchname: "HQ",
    divisionname: "ITD",
  },
  {
    id: "2",
    branchid: "2",
    divisionid: "2",
    groups: "",
    school: "Oak Consulting",
    fullname: "Viewer user",
    email: "renuka@oakconsulting.com.sg",
    role: ["viewer"],
    branchname: "balestier",
    divisionname: "Innovation",
  },
  {
    id: "3",
    branchid: "3",
    divisionid: "3",
    groups: "",
    school: "Oak Consulting",
    fullname: "Approver user",
    email: "zafar@oakconsulting.com.sg",
    role: ["approver"],
    branchname: "HQ",
    divisionname: "ITD",
  },
  {
    id: "4",
    branchid: "4",
    divisionid: "4",
    groups: "",
    school: "Oak Consulting",
    fullname: "Admin user",
    email: "victorng@oakconsulting.com.sg",
    role: ["admin"],
    branchname: "HQ",
    divisionname: "ITD",
  },
  {
    id: "5",
    branchid: "5",
    divisionid: "5",
    groups: "",
    school: "Oak Consulting",
    fullname: "ApproverCreator user",
    email: "manoranjan@oakconsulting.com.sg",
    role: ["approver", "creator"],
    branchname: "HQ",
    divisionname: "ITD",
  },
];

export const FORMAT_DATE_TIME = "DD MMM YYYY hh:mm A";
export const FORMAT_BREAK_DATE_TIME = "DD MMM YYYY \n hh:mm A";

export const ALL_SPACES_REG = /^(?!\s*$).+$/;
