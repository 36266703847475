import { SvgIcon, SvgIconProps } from "@mui/material";

const EyeOffIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon role="img" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.4531 5.26522C5.85123 4.9612 6.41289 4.99868 6.76711 5.35289L10.5832 9.16897C10.5855 9.17127 10.5878 9.17359 10.5901 9.17592L14.824 13.4098C14.8264 13.4122 14.8287 13.4145 14.8311 13.4169L18.6471 17.2329C18.8511 17.4369 18.9571 17.7191 18.9378 18.007C18.9184 18.2949 18.7757 18.5604 18.5462 18.7353C16.6671 20.1677 14.3789 20.9612 12.0163 20.9999L12 21C8.10049 21 5.10448 18.7736 3.14546 16.6839C2.15683 15.6294 1.40207 14.5787 0.894336 13.7925C0.63985 13.3985 0.445792 13.0684 0.313971 12.834C0.248023 12.7168 0.19754 12.6233 0.162753 12.5576C0.145357 12.5247 0.131875 12.4987 0.122338 12.4802L0.11099 12.458L0.107539 12.4511L0.10637 12.4488C0.10637 12.4488 0.105573 12.4472 1 12L0.105573 12.4472C-0.0397387 12.1566 -0.0347895 11.8135 0.118844 11.5272C1.43015 9.08342 3.24891 6.94837 5.4531 5.26522ZM2.14257 12.0032C2.25165 12.1915 2.39592 12.4311 2.57441 12.7075C3.03543 13.4213 3.71817 14.3706 4.60454 15.3161C6.39395 17.2248 8.89512 18.9969 11.9918 19C13.5373 18.9733 15.0437 18.5524 16.3714 17.7856L14.0497 15.464C13.891 15.5635 13.7251 15.6519 13.5531 15.7286C13.0625 15.9472 12.5328 16.0647 11.9957 16.0742C11.4586 16.0837 10.9252 15.9849 10.4271 15.7837C9.92902 15.5825 9.47657 15.2831 9.09674 14.9033C8.71691 14.5234 8.41747 14.071 8.21629 13.5729C8.01511 13.0748 7.91631 12.5413 7.92579 12.0043C7.93527 11.4672 8.05282 10.9375 8.27145 10.4469C8.34805 10.2749 8.43652 10.109 8.53604 9.95026L5.9871 7.40131C4.45031 8.70137 3.14935 10.2582 2.14257 12.0032ZM10.0279 11.4421C9.96372 11.6345 9.92907 11.836 9.92548 12.0395C9.92074 12.3081 9.97014 12.5748 10.0707 12.8239C10.1713 13.0729 10.321 13.2991 10.511 13.489C10.7009 13.679 10.9271 13.8287 11.1761 13.9293C11.4252 14.0299 11.6919 14.0793 11.9604 14.0745C12.164 14.0709 12.3655 14.0363 12.5579 13.9721L10.0279 11.4421Z"
        fill="currentColor"
      />
      <path
        d="M11.9977 4.99999C11.3682 4.99851 10.7408 5.07022 10.1279 5.21368C9.59017 5.33955 9.0522 5.00566 8.92632 4.46791C8.80045 3.93016 9.13434 3.39219 9.67209 3.26631C10.4355 3.08762 11.2171 2.99824 12.0012 2.99999C15.9001 3.00043 18.8957 5.22664 20.8545 7.31605C21.8432 8.37059 22.5979 9.42131 23.1057 10.2075C23.3602 10.6015 23.5542 10.9316 23.686 11.166L23.8944 11.5528C24.0395 11.8429 24.0348 12.1853 23.8819 12.4714C23.2421 13.6684 22.479 14.7953 21.6052 15.8338C21.2496 16.2564 20.6187 16.3107 20.1962 15.9551C19.7736 15.5996 19.7193 14.9687 20.0749 14.5461C20.7439 13.751 21.3406 12.8982 21.858 11.9978C21.7488 11.8092 21.6044 11.5693 21.4256 11.2925C20.9646 10.5787 20.2818 9.6294 19.3955 8.68394C17.6045 6.77356 15.1005 4.99999 12 4.99999L11.9977 4.99999Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.292893 0.292893C0.683418 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L23.7071 22.2929C24.0976 22.6834 24.0976 23.3166 23.7071 23.7071C23.3166 24.0976 22.6834 24.0976 22.2929 23.7071L0.292893 1.70711C-0.097631 1.31658 -0.097631 0.683417 0.292893 0.292893Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
export default EyeOffIcon;
